import React, { useState, useEffect, useRef, Fragment } from 'react'
import axios from 'axios'
import '../css/App.css'
import FileSaver from 'file-saver'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { ButtonTaltech } from './ButtonTaltech'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import * as xlsx from 'xlsx'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'

export const DefaultSettings = () => {
  const emptyDefaultValue = {
    updated_on: '',
    refrigerants: '',
    building_services_a1_a3: '',
    building_construction_a5: '',
    transport: '',
    eol_waste: '',
    service_lives_building: '',
    demolition_c1: '',
    distances_imported_materials: '',
    concrete_columns_densities: '',
    concrete_structures: '',
    wood_structures: '',
    energy_limit_values_b6: '',
    energy_scenarios: '',
    energy_emission_factors_b6: ''
  }
  const [defaultValueResponse, setDefaultValueResponse] = useState('')
  const [defaultValueData, setDefaultValueUpdateData] = useState('')
  const [defaultValueDialog, setDefaultValueDialog] = useState(false)
  const [defaultValueUpdateDialog, setDefaultValueUpdateDialog] = useState(false)
  const [deleteDefaultValueDialog, setDeleteDefaultValueDialog] = useState(false)
  const [deleteDefaultValuesDialog, setDeleteDefaultValuesDialog] = useState(false)
  const [defaultValue, setDefaultValue] = useState('')
  const [defaultValues, setDefaultValues] = useState([])
  const [selectedDefaultValues, setSelectedDefaultValues] = useState(null)
  const [submittedDefaultValue, setSubmittedDefaultValue] = useState(false)
  const [globalDefaultValueFilter, setGlobalDefaultValueFilter] = useState(null)
  const toastDefaultValue = useRef(null)
  const dtDefaultValue = useRef(null)
  /* const apiUrl = process.env.REACT_APP_API_URL */

  const getDefaultValues = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/default_values`)
      .then((response) => {
        setDefaultValues(response.data)
        // eslint-disable-next-line no-console
        console.log(defaultValues)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  useEffect(() => {
    getDefaultValues()
  }, [])

  function handleDefaultValueUpdate (id) {
    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/asset/default_values/${id}`, defaultValue, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
      .then((response) => {
        setDefaultValueUpdateData(response.data)
        // eslint-disable-next-line no-console
        console.log(`Updated defaultvalues- ${defaultValueData}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  function onDeleteDefaultValues (id) {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/asset/default_values/${id}`)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response.data)
        // eslint-disable-next-line no-console
        console.log(`Deleted default values with ID ${id}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  const onDefaultValuesInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    const _defaultValue = { ...defaultValue }
    _defaultValue[`${name}`] = val
    setDefaultValue(_defaultValue)
  }

  const handleAddDefaultValues = (e) => {
    e.preventDefault()
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/asset/default_values`, defaultValue, {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
      })
      .then(
        (response) => {
          setDefaultValueResponse(response)
          // eslint-disable-next-line no-console
          console.log(defaultValueResponse)
          saveDefaultValues()
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error.message)
        }
      )
  }

  const openNewDefaultValue = () => {
    setDefaultValue(emptyDefaultValue)
    setSubmittedDefaultValue(false)
    setDefaultValueDialog(true)
  }

  const hideDefaultValueDialog = () => {
    setSubmittedDefaultValue(false)
    setDefaultValueDialog(false)
  }

  const hideDefaultValueUpdateDialog = () => {
    setSubmittedDefaultValue(false)
    setDefaultValueUpdateDialog(false)
  }

  const hideDeleteDefaultValueDialog = () => {
    setDeleteDefaultValueDialog(false)
  }

  const hideDeleteDefaultValuesDialog = () => {
    setDeleteDefaultValuesDialog(false)
  }
  const findDefaultValuesIndexById = (id) => {
    let index = -1
    for (let i = 0; i < defaultValues.length; i++) {
      if (defaultValues[i].id === id) {
        index = i
        break
      }
    }
    return index
  }

  const saveDefaultValues = () => {
    setSubmittedDefaultValue(true)
    getDefaultValues()
    toastDefaultValue.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Default values Created',
      life: 3000
    })
    setDefaultValueDialog(false)
    setDefaultValue(emptyDefaultValue)
  }

  const updateDefaultValue = () => {
    const _defaultValues = [...defaultValues]
    const _defaultValue = { ...defaultValue }
    if (defaultValue.id) {
      const index = findDefaultValuesIndexById(defaultValue.id)
      _defaultValues[index] = _defaultValue
      handleDefaultValueUpdate(defaultValue.id)
      toastDefaultValue.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'Default values Updated',
        life: 3000
      })
    }
    setDefaultValues(_defaultValues)
    setDefaultValueUpdateDialog(false)
    setDefaultValue(emptyDefaultValue)
  }

  const editDefaultValues = (defaultValue) => {
    setDefaultValue({ ...defaultValue })
    setDefaultValueUpdateDialog(true)
  }

  const confirmDeleteDefaultValue = (defaultValue) => {
    setDefaultValue(defaultValue)
    setDeleteDefaultValueDialog(true)
  }

  const deleteDefaultValue = () => {
    const _defaultvalues = defaultValues.filter((val) => val.id !== defaultValue.id)
    onDeleteDefaultValues(defaultValue.id)
    setDefaultValues(_defaultvalues)
    setDeleteDefaultValueDialog(false)
    setDefaultValue(emptyDefaultValue)
    toastDefaultValue.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Default values Deleted',
      life: 3000
    })
  }

  const exportDefaultValueExcel = () => {
    const worksheet = xlsx.utils.json_to_sheet(defaultValues)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    saveAsExcelFile(excelBuffer, 'default_settings_values')
  }

  const saveAsExcelFile = (buffer, fileName) => {
    const data = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    })
    FileSaver.saveAs(data, fileName + 'default_settings_values' + '.xlsx')
  }

  const deleteSelectedDefaultValues = () => {
    const _defaultValues = defaultValues.filter(
      (val) => !deleteSelectedDefaultValues.includes(val)
    )

    setDefaultValues(_defaultValues)
    setDeleteDefaultValuesDialog(false)
    setSelectedDefaultValues(null)
    toastDefaultValue.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Default values Deleted',
      life: 3000
    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editDefaultValues(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteDefaultValue(rowData)}
        />
      </Fragment>
    )
  }

  const headerDefaultValue = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="flex flex-wrap gap-2">
        <ButtonTaltech size="small" label="Export" onClick={exportDefaultValueExcel}/>
        {defaultValues.length !== 0
          ? (<></>)
          : (
            <ButtonTaltech size="small" label="New" onClick={openNewDefaultValue}/>
            )}
      </div>
      <TextField
        type="search"
        placeholder="Search..."
        value={globalDefaultValueFilter}
        onInput={(e) => setGlobalDefaultValueFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: '8px' }} />
            </InputAdornment>
          )
        }}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )

  const defaultValueDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDefaultValueDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={handleAddDefaultValues}/>
    </Fragment>
  )

  const defaultValueUpdateDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDefaultValueUpdateDialog}
      />
      <Button label="Update" icon="pi pi-check" onClick={updateDefaultValue}/>
    </Fragment>
  )

  const deleteDefaultValueDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteDefaultValueDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteDefaultValue}
      />
    </Fragment>
  )

  const deleteDefaultValuesDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteDefaultValuesDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedDefaultValues}
      />
    </Fragment>
  )

  /*  const verticalColumns = (defaultValue) => {
    return (
      <div>
        <div><strong>Refrigerants:</strong> {defaultValue.refrigerants}</div>
        <div><strong>Building construction a5:</strong> {defaultValue.building_construction_a5}</div>
        <div><strong>Building services a1-a3:</strong> {defaultValue.building_services_a1_a3}</div>
        <div><strong>Transport:</strong> {defaultValue.transport}</div>
      </div>
    )
  } */

  return (
    <article>
      <div>
        <Toast ref={toastDefaultValue}/>
        <div className="card" >
          <DataTable
            ref={dtDefaultValue}
            value={defaultValues}
            selection={selectedDefaultValues}
            onSelectionChange={(e) => setSelectedDefaultValues(e.value)}
            dataKey="id"
            stripedRows
            size={'small'}
            rows={10}
            editMode="row"
            sortMode="multiple"
            globalFilter={globalDefaultValueFilter}
            header={headerDefaultValue}
            scrollable
          >
            <Column
              field="refrigerants"
              header="Refrigerants"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="building_services_a1_a3"
              header="Building services A1-A3"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="building_construction_a5"
              header="Building construction A5"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="transport"
              header="Transport"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="eol_waste"
              header="Eol waste processing"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="service_lives_building"
              header="Service lives building"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="demolition_c1"
              header="Demolition C1"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="distances_imported_materials"
              header="Distances imported materials"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="concrete_columns_densities"
              header="Concrete Columns Densities"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="concrete_structures"
              header="Concrete structures"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="wood_structures"
              header="Wood structures"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="energy_limit_values_b6"
              header="Energy limit values B6"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="energy_scenarios"
              header="Energy scenarios B6"
              style={{ minWidth: '0.5rem' }}
            ></Column>
            <Column
              field="energy_emission_factors_b6"
              header="Energy emission factors B6"
              style={{ minWidth: '0.5rem' }}
            ></Column>

            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: '9rem' }}
            ></Column>
          </DataTable>
        </div>
        <Dialog
          visible={defaultValueUpdateDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Modify default setting values"
          modal
          className="p-fluid"
          footer={defaultValueUpdateDialogFooter}
          onHide={hideDefaultValueUpdateDialog}
        >
          <div className="field">
            <label
              htmlFor="refrigerants_update"
              className="font-bold labelAsterix p-required"
            >
              Refrigerant url
            </label>
            <InputTextarea
              id="refrigerants_update"
              value={defaultValue.refrigerants}
              onChange={(e) => onDefaultValuesInputChange(e, 'refrigerants')}
              rows={3}
              cols={20}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.refrigerants
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="building_services_a1_a3_update"
              className="font-bold labelAsterix p-required"
            >
              Building services a1_a3 url
            </label>
            <InputTextarea
              id="building_services_a1_a3_update"
              value={defaultValue.building_services_a1_a3}
              onChange={(e) => onDefaultValuesInputChange(e, 'building_services_a1_a3')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="building_construction_a5_update"
              className="font-bold labelAsterix p-required"
            >
              Building construction a5 url
            </label>
            <InputTextarea
              id="building_construction_a5_update"
              value={defaultValue.building_construction_a5}
              onChange={(e) => onDefaultValuesInputChange(e, 'building_construction_a5')}
              rows={3}
              cols={20}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.building_construction_a5
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="transport_update"
              className="font-bold labelAsterix p-required"
            >
              Transport url
            </label>
            <InputTextarea
              id="transport"
              value={defaultValue.transport}
              onChange={(e) => onDefaultValuesInputChange(e, 'transport')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="eol_waste_update"
              className="font-bold labelAsterix p-required"
            >
              Eol waste processing url
            </label>
            <InputTextarea
              id="eol_waste_update"
              value={defaultValue.eol_waste}
              onChange={(e) => onDefaultValuesInputChange(e, 'eol_waste')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="service_lives_building_update"
              className="font-bold labelAsterix p-required"
            >
              Service lives building url
            </label>
            <InputTextarea
              id="service_lives_building_update"
              value={defaultValue.service_lives_building}
              onChange={(e) => onDefaultValuesInputChange(e, 'service_lives_building')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="demolition_c1_update"
              className="font-bold labelAsterix p-required"
            >
              Demolition c1 url
            </label>
            <InputTextarea
              id="demolition_c1_update"
              value={defaultValue.demolition_c1}
              onChange={(e) => onDefaultValuesInputChange(e, 'demolition_c1')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="distances_imported_materials_update"
              className="font-bold labelAsterix p-required"
            >
              Distance imported materials url
            </label>
            <InputTextarea
              id="distances_imported_materials_update"
              value={defaultValue.distances_imported_materials}
              onChange={(e) => onDefaultValuesInputChange(e, 'distances_imported_materials')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="distances_imported_materials_update"
              className="font-bold labelAsterix p-required"
            >
              Demolition c1 url
            </label>
            <InputTextarea
              id="distances_imported_materials_update"
              value={defaultValue.distances_imported_materials}
              onChange={(e) => onDefaultValuesInputChange(e, 'distances_imported_materials')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="concrete_columns_densities_update"
              className="font-bold labelAsterix p-required"
            >
              Concrete column densities url
            </label>
            <InputTextarea
              id="concrete_columns_densities_update"
              value={defaultValue.concrete_columns_densities}
              onChange={(e) => onDefaultValuesInputChange(e, 'concrete_columns_densities')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="concrete_structures_update"
              className="font-bold labelAsterix p-required"
            >
              Concrete structures url
            </label>
            <InputTextarea
              id="concrete_structures_update"
              value={defaultValue.concrete_structures}
              onChange={(e) => onDefaultValuesInputChange(e, 'concrete_structures')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="wood_structures_update"
              className="font-bold labelAsterix p-required"
            >
              Wood structures url
            </label>
            <InputTextarea
              id="wood_structures_update"
              value={defaultValue.wood_structures}
              onChange={(e) => onDefaultValuesInputChange(e, 'wood_structures')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="energy_limit_values_b6_update"
              className="font-bold labelAsterix p-required"
            >
              Energy limit values b6 url
            </label>
            <InputTextarea
              id="energy_limit_values_b6_update"
              value={defaultValue.energy_limit_values_b6}
              onChange={(e) => onDefaultValuesInputChange(e, 'energy_limit_values_b6')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="energy_scenarios_update"
              className="font-bold labelAsterix p-required"
            >
              Energy scenarios url
            </label>
            <InputTextarea
              id="energy_scenarios_update"
              value={defaultValue.energy_scenarios}
              onChange={(e) => onDefaultValuesInputChange(e, 'energy_scenarios')}
              rows={3}
              cols={20}
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="energy_emission_factors_b6_update"
              className="font-bold labelAsterix p-required"
            >
              Energy emission factors b6 url
            </label>
            <InputTextarea
              id="energy_emission_factors_b6_update"
              value={defaultValue.energy_emission_factors_b6}
              onChange={(e) => onDefaultValuesInputChange(e, 'energy_emission_factors_b6')}
              rows={3}
              cols={20}
              required
            />
          </div>
        </Dialog>

        <Dialog
          visible={defaultValueDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Add Default settings  values"
          modal
          className="p-fluid"
          footer={defaultValueDialogFooter}
          onHide={hideDefaultValueDialog}
        >
          <Typography variant="subtitle3" component="div">
            Ensure that the url adding has general access granted. <a href="https://shorturl.at/zqDAw" target="_blank" rel="noreferrer">**hint**</a>
          </Typography>
          <br/>
          <div className="field">
            <label
              htmlFor="refrigerants"
              className="font-bold labelAsterix p-required"
            >
              Refrigerants url
            </label>
            <InputText
              p-required="true"
              id="refrigerants"
              value={defaultValue.refrigerants}
              onChange={(e) => onDefaultValuesInputChange(e, 'title')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.refrigerants
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="building_services_a1_a3"
              className="font-bold labelAsterix p-required"
            >
              Building services a1_a3 url
            </label>
            <InputText
              p-required="true"
              id="building_services_a1_a3"
              value={defaultValue.building_services_a1_a3}
              onChange={(e) => onDefaultValuesInputChange(e, 'building_services_a1_a3')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.building_services_a1_a3
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="building_construction_a5"
              className="font-bold labelAsterix p-required"
            >
              Building construction a5 url
            </label>
            <InputText
              p-required="true"
              id="building_construction_a5"
              value={defaultValue.building_construction_a5}
              onChange={(e) => onDefaultValuesInputChange(e, 'building_services_a1_a3')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.building_construction_a5
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="transport"
              className="font-bold labelAsterix p-required"
            >
              Transport url
            </label>
            <InputText
              p-required="true"
              id="transport"
              value={defaultValue.transport}
              onChange={(e) => onDefaultValuesInputChange(e, 'transport')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.transport
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="eol_waste"
              className="font-bold labelAsterix p-required"
            >
              Eol waste processing url
            </label>
            <InputText
              p-required="true"
              id="eol_waste"
              value={defaultValue.eol_waste}
              onChange={(e) => onDefaultValuesInputChange(e, 'eol_waste')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.eol_waste
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="service_lives_building"
              className="font-bold labelAsterix p-required"
            >
              Service lives building url
            </label>
            <InputText
              p-required="true"
              id="service_lives_building"
              value={defaultValue.service_lives_building}
              onChange={(e) => onDefaultValuesInputChange(e, 'service_lives_building')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.service_lives_building
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="demolition_c1"
              className="font-bold labelAsterix p-required"
            >
              Demolition c1 url
            </label>
            <InputText
              p-required="true"
              id="demolition_c1"
              value={defaultValue.demolition_c1}
              onChange={(e) => onDefaultValuesInputChange(e, 'demolition_c1')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.demolition_c1
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="distances_imported_materials"
              className="font-bold labelAsterix p-required"
            >
              Distances imported materials url
            </label>
            <InputText
              p-required="true"
              id="distances_imported_materials"
              value={defaultValue.distances_imported_materials}
              onChange={(e) => onDefaultValuesInputChange(e, 'distances_imported_materials')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.distances_imported_materials
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor=" concrete_columns_densities"
              className="font-bold labelAsterix p-required"
            >
              Concrete columns densities url
            </label>
            <InputText
              p-required="true"
              id="concrete_columns_densities"
              value={defaultValue.concrete_columns_densities}
              onChange={(e) => onDefaultValuesInputChange(e, ' concrete_columns_densities')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.concrete_columns_densities
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="concrete_structures"
              className="font-bold labelAsterix p-required"
            >
              Concrete structures url
            </label>
            <InputText
              p-required="true"
              id="concrete_structures"
              value={defaultValue.concrete_columns_densities}
              onChange={(e) => onDefaultValuesInputChange(e, 'concrete_structures')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.concrete_structures
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="wood_structures"
              className="font-bold labelAsterix p-required"
            >
              Wood structures url
            </label>
            <InputText
              p-required="true"
              id="wood_structures"
              value={defaultValue.wood_structures}
              onChange={(e) => onDefaultValuesInputChange(e, 'wood_structures')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.wood_structures
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor=" energy_limit_values_b6"
              className="font-bold labelAsterix p-required"
            >
              Energy limit values b6 url
            </label>
            <InputText
              p-required="true"
              id="energy_limit_values_b6"
              value={defaultValue.energy_limit_values_b6}
              onChange={(e) => onDefaultValuesInputChange(e, 'energy_limit_values_b6')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.energy_limit_values_b6
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="energy_scenarios"
              className="font-bold labelAsterix p-required"
            >
              Energy Scenarios url
            </label>
            <InputText
              p-required="true"
              id="energy_scenarios"
              value={defaultValue.energy_scenarios}
              onChange={(e) => onDefaultValuesInputChange(e, 'energy_scenarios')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.energy_scenarios
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="energy_emission_factors_b6"
              className="font-bold labelAsterix p-required"
            >
              Energy emission factors b6 url
            </label>
            <InputText
              p-required="true"
              id="energy_emission_factors_b6"
              value={defaultValue.energy_emission_factors_b6}
              onChange={(e) => onDefaultValuesInputChange(e, 'energy_emission_factors_b6')}
              required
              className={classNames({
                'p-invalid': submittedDefaultValue && !defaultValue.energy_emission_factors_b6
              })}
            />
          </div>
        </Dialog>

        <Dialog
          visible={deleteDefaultValuesDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteDefaultValueDialogFooter}
          onHide={hideDeleteDefaultValueDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {defaultValue && (
              <span>
                Are you sure you want to delete <b>{defaultValue.updated_on}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteDefaultValueDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteDefaultValuesDialogFooter}
          onHide={hideDeleteDefaultValuesDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {defaultValue && (
              <span>Are you sure you want to delete the selected default values?</span>
            )}
          </div>
        </Dialog>
      </div>
    </article>
  )
}

import React from 'react'
import Container from '@mui/material/Container'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { OverviewCard } from './OverviewCard'

export const Overview = () => {
  return (
    <div className="card">
      <AuthenticatedTemplate>
        <Container maxWidth="xl">
        <OverviewCard/>
        </Container>
      </AuthenticatedTemplate>
    </div>
  )
}

import React from 'react'
import '../css/App.css'
import { useLocation } from 'react-router-dom'
import { BreadCrumb } from 'primereact/breadcrumb'
import { useTranslation } from 'react-i18next'

export const Breadcrumbs = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const pathname = location.pathname.split('/').filter(x => x)

  const items = pathname.map((value, index) => {
    const to = `/${pathname.slice(0, index + 1).join('/')}`
    let label = value

    if (value === 'navdrawer') {
      label = t('content_mngt', 'EHEA content management')
    } else if (value === 'reports') {
      label = t('reports', 'Reports')
    } else if (value === 'news') {
      label = t('news', 'News')
    } else if (index === 0) {
      label = 'Home'
    }
    return { label, url: to }
  })

  const home = { icon: 'pi pi-home', url: '/' }

  return <BreadCrumb className="no-scrollbar" model={items} home={home}/>
}

import React, { useEffect, useState } from 'react'
import Taltech from '../assets/taltech_white.png'
import Eu from '../assets/eu_logo.jpg'
import Ministry from '../assets/kliimaministeerium.png'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated
} from '@azure/msal-react'
import { loginRequest } from '../authConfig'
import { Button } from './Button'
import '../css/header.css'
import { useLocation, useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line no-unused-vars
export const Header = () => {
  const location = useLocation()
  const isAuthenticated = useIsAuthenticated()
  const { instance } = useMsal()
  const { accounts } = useMsal()
  const user = accounts[0]
  const [allowedUsers, setAllowedUsers] = useState([])
  const { t, i18n } = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
      .then(r => {
        console.log('language change is ready:', r)
      }).catch(err => {
        console.error('language change failed:', err)
      })
  }

  const getAllowedUsers = () => {
    axios
      .get(`http://localhost:5004/asset/users`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setAllowedUsers(response.data)
          // eslint-disable-next-line no-console
          console.log(allowedUsers)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  useEffect(() => {
    getAllowedUsers()
  }, [])

  const isUserApproved = (username) => {
    return allowedUsers.includes(username)
  }

  const handleLoginRedirect = () => {
    // eslint-disable-next-line no-console
    instance.loginRedirect(loginRequest).catch((error) => console.log(error))
  }

  const handleLogoutRedirect = () => {
    // eslint-disable-next-line no-console
    instance.logoutRedirect().catch((error) => console.log(error))
  }

  const navigate = useNavigate()

  const navigateHome = () => {
    navigate('/', { replace: true })
  }

  const navigateDashboard = () => {
    navigate('/navdrawer', { replace: true })
  }

  return (
    <header>
      <div className="wrapper-white">
        <div className="div-green">
          <p className="p-green">EHEA</p>
        </div>
        <div className="top_right">
          <div className="left-section">
            <nav>
              <Typography sx={{ flexGrow: 1 }}>
                {isAuthenticated && location.pathname !== '/navdrawer' && (
                  isUserApproved
                    ? (<Link className="link_left" onClick={navigateDashboard} underline="hover">
                      <b> {t('content_mngt')}</b>
                    </Link>)
                    : (<b> {t('contact_support')}</b>)

                )}
              </Typography>
            </nav>
          </div>
        </div>
        <div className="right-section">
          <div className="language-switcher">
            <SearchIcon style={{ marginLeft: '8px' }}/>
            <a href="#">{t('search')}</a> |
            <a href="#" onClick={() => changeLanguage('ee')}>EST</a>
            <a href="#" onClick={() => changeLanguage('en')}>EN</a>
          </div>
        </div>
      </div>

      <div className="wrapper">

        <div>
          <div id="pointer"></div>
          <img src={Taltech} onClick={navigateHome} alt="taltech logo"/>
        </div>
        <div className="left-logos">
          <div className="div-ministry">
            <img className="img-ministry" src={Ministry} alt="kliimaministeerium  logo"/>
          </div>
          <div className="div-eu">
            <img className="img-eu" src={Eu} alt="eu logo"/>
          </div>
        </div>
        <AuthenticatedTemplate>
            <div className="right-section-bottom">
              <>
                {isUserApproved
                  ? (
                    <span className="welcome">
                {user && <b>{user.name}</b>}
              </span>)
                  : null}
                <Button
                  primary
                  size="small"
                  onClick={handleLogoutRedirect}
                  label={t('logout')}
                />
              </>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div className="right-section-bottom">
            <Button
              primary
              size="small"
              onClick={handleLoginRedirect}
              label={t('login')}
            />
          </div>
        </UnauthenticatedTemplate>
      </div>
    </header>
  )
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ee'],
    fallbackLng: 'en',
    lng: 'ee',
    debug: true,
    backend: {
      loadPath: '/locales/{{lng}}.json'
    },
    detection: {
      order: ['querystring', 'cookie'],
      caches: ['cookie']
    },
    interpolation: {
      escapeValue: false
    }
  }).then(r => {
    console.log('i18next is ready:', r)
  }).catch(err => {
    console.error('i18next initialization failed:', err)
  })

export default i18n

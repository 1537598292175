import React, { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { ButtonTaltech } from './ButtonTaltech'
import { InputTextarea } from 'primereact/inputtextarea'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { useMsal } from '@azure/msal-react'

export const Notification = () => {
  const { accounts } = useMsal()
  const user = accounts[0]
  const author = user.name
  const addedDate = new Date().toISOString().slice(0, 19)
  const [addedOn, setAddedOn] = useState(addedDate)
  const emptyNotification = {
    added_on: addedOn,
    author,
    message: '',
    share: ''
  }
  const shares = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' }
  ]
  const [notificationUpdateData, setNotificationUpdateData] = useState('')
  const [notificationResponse, setNotificationResponse] = useState('')
  const [notification, setNotification] = useState('')
  const [notifications, setNotifications] = useState(null)
  const [selectedNotifications, setSelectedNotifications] = useState(null)
  const [submittedNotification, setSubmittedNotification] = useState(false)
  const [globalNotificationFilter, setGlobalNotificationFilter] =
    useState(null)
  const [notificationDialog, setNotificationDialog] = useState(false)
  const [notificationUpdateDialog, setNotificationUpdateDialog] =
    useState(false)
  const [deleteNotificationDialog, setDeleteNotificationDialog] =
    useState(false)
  const [deleteNotificationsDialog, setDeleteNotificationsDialog] =
    useState(false)
  const toastNotification = useRef(null)
  const dtNotification = useRef(null)

  const getNotifications = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/notifications`)
      .then((response) => {
        setNotifications(response.data)
        // eslint-disable-next-line no-console
        console.log(notifications)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }
  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAddedOn(new Date().toISOString().slice(0, 19))
    }, 10)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const handleAddNotification = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/asset/notifications`, notification, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
      .then(
        (response) => {
          setNotificationResponse(response)
          // eslint-disable-next-line no-console
          console.log(notificationResponse)
          saveNotification()
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error.message)
        }
      )
  }

  function handleNotificationUpdate (id) {
    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/asset/notifications/${id}`, notification, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
      .then((response) => {
        setNotificationUpdateData(response.data)
        // eslint-disable-next-line no-console
        console.log(`Updated user ${notificationUpdateData}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  function onDeleteNotification (id) {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/asset/notifications/${id}`)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response.data)
        // eslint-disable-next-line no-console
        console.log(`Deleted notification with ID ${id}`)
        getNotifications()
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  /* function onUpdateNotification(id) {
    axios
      .put(`http://localhost:5004/notifications/${id}`, notification, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response.data);
        // eslint-disable-next-line no-console
        console.log(`Updated notification with ID ${id}`);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  } */

  /* const handleMessage = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const handleShare = (e) => {
    e.preventDefault();
    setShare(e.target.value);
  }; */
  const onNotificationInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    const _notification = { ...notification }
    _notification[`${name}`] = val
    setNotification(_notification)
  }
  const openNewNotification = () => {
    setNotification(emptyNotification)
    setSubmittedNotification(false)
    setNotificationDialog(true)
  }

  const hideNotificationDialog = () => {
    setSubmittedNotification(false)
    setNotificationDialog(false)
  }

  const hideNotificationUpdateDialog = () => {
    setSubmittedNotification(false)
    setNotificationUpdateDialog(false)
  }

  const hideDeleteNotificationDialog = () => {
    setDeleteNotificationDialog(false)
  }

  const hideDeleteNotificationsDialog = () => {
    setDeleteNotificationsDialog(false)
  }

  const saveNotification = () => {
    setSubmittedNotification(true)
    getNotifications()
    toastNotification.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Notification Created',
      life: 3000
    })
    setNotificationDialog(false)
    setNotification(emptyNotification)
  }

  const editNotification = (notification) => {
    setNotification({ ...notification })
    setNotificationUpdateDialog(true)
  }
  const findNotificationIndexById = (id) => {
    let index = -1
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].id === id) {
        index = i
        break
      }
    }
    return index
  }

  const updateNotification = () => {
    const _notifications = [...notifications]
    const _notification = { ...notification }
    if (notification.id) {
      const index = findNotificationIndexById(notification.id)
      _notifications[index] = _notification
      handleNotificationUpdate(notification.id)
      toastNotification.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'Notification Updated',
        life: 3000
      })
    }
    setNotifications(_notifications)
    setNotificationUpdateDialog(false)
    setNotification(emptyNotification)
  }

  const confirmDeleteNotification = (notification) => {
    setNotification(notification)
    setDeleteNotificationDialog(true)
  }

  const deleteNotification = () => {
    const _notifications = notifications.filter(
      (val) => val.id !== notification.id
    )
    onDeleteNotification(notification.id)
    setNotification(_notifications)
    setDeleteNotificationDialog(false)
    setNotification(emptyNotification)
    toastNotification.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Notification Deleted',
      life: 3000
    })
  }

  const deleteSelectedNotifications = () => {
    const _notifications = notifications.filter(
      (val) => !deleteSelectedNotifications.includes(val)
    )
    setNotifications(_notifications)
    setDeleteNotificationsDialog(false)
    setSelectedNotifications(null)
    toastNotification.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Notifications Deleted',
      life: 3000
    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editNotification(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteNotification(rowData)}
        />
      </Fragment>
    )
  }

  const headerNotification = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="flex flex-wrap gap-2">
        <ButtonTaltech size="small" label="New" onClick={openNewNotification} />
      </div>
      <TextField
        type="search"
        placeholder="Search..."
        value={globalNotificationFilter}
        onInput={(e) => setGlobalNotificationFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: '8px' }} />
            </InputAdornment>
          )
        }}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )

  const notificationUpdateDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideNotificationUpdateDialog}
      />
      <Button label="Update" icon="pi pi-check" onClick={updateNotification} />
    </Fragment>
  )

  const notificationDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideNotificationDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={handleAddNotification} />
    </Fragment>
  )

  const deleteNotificationDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteNotificationDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteNotification}
      />
    </Fragment>
  )

  const deleteNotificationsDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteNotificationsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedNotifications}
      />
    </Fragment>
  )

  return (
    <article>
      <div>
        <Toast ref={toastNotification} />
        <div className="card">
          <DataTable
            ref={dtNotification}
            value={notifications}
            selection={selectedNotifications}
            onSelectionChange={(e) => setSelectedNotifications(e.value)}
            dataKey="id"
            paginator
            stripedRows
            size={'small'}
            rows={10}
            editMode="row"
            sortMode="multiple"
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalNotificationFilter}
            header={headerNotification}
          >
            {/*  <Column selectionMode="multiple" exportable={false}></Column> */}
            <Column
              field="id"
              header="ID"
              style={{ minWidth: '2rem', display: 'none' }}
            ></Column>
            <Column
              field="added_on"
              header="Modified on"
              style={{ minWidth: '6rem' }}
            ></Column>
            <Column
              field="author"
              header="Author"
              style={{ minWidth: '6rem' }}
            ></Column>
            <Column
              field="message"
              header="message"
              style={{ minWidth: '24rem' }}
            ></Column>
            <Column
              field="share"
              header="share"
              style={{ minWidth: '1rem' }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: '2rem' }}
            ></Column>
          </DataTable>
        </div>

        <Dialog
          visible={notificationUpdateDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Modify notification"
          modal
          className="p-fluid"
          footer={notificationUpdateDialogFooter}
          onHide={hideNotificationUpdateDialog}
        >
          <div className="field">
            <label
              htmlFor="message_update"
              className="font-bold labelAsterix p-required"
            >
              Message
            </label>
            <InputTextarea
              id="message_update"
              value={notification.message}
              maxLength={60}
              onChange={(e) => onNotificationInputChange(e, 'message')}
              rows={3}
              cols={20}
              required
              autoFocus
              className={classNames({
                'p-invalid': submittedNotification && !notification.message
              })}
            />
            {submittedNotification && !notification.message && (
              <small className="p-error">Message is required.</small>
            )}
          </div>
          <div className="field">
            <label
              htmlFor="share_update"
              className="font-bold labelAsterix p-required"
            >
              Share
            </label>
            <Dropdown
              id="share_update"
              value={notification.share}
              onChange={(e) => onNotificationInputChange(e, 'share')}
              options={shares}
              optionLabel="name"
              placeholder="Do you want to share?"
              required
            />
          </div>
        </Dialog>

        <Dialog
          visible={notificationDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Add Notification"
          modal
          className="p-fluid"
          footer={notificationDialogFooter}
          onHide={hideNotificationDialog}
        >
          <div className="field">
            <label
              htmlFor="message"
              className="font-bold labelAsterix p-required"
            >
              Message
            </label>
            <InputTextarea
              id="message"
              value={notification.message}
              maxLength={60}
              onChange={(e) => onNotificationInputChange(e, 'message')}
              rows={2}
              cols={20}
              required
              autoFocus
              className={classNames({
                'p-invalid': submittedNotification && !notification.message
              })}
            />
            {submittedNotification && !notification.message && (
              <small className="p-error">Message is required.</small>
            )}
          </div>
          <div className="field">
            <label
              htmlFor="share"
              className="font-bold labelAsterix p-required"
            >
              Share
            </label>
            <Dropdown
              id="share"
              value={notification.share}
              onChange={(e) => onNotificationInputChange(e, 'share')}
              options={shares}
              optionLabel="name"
              placeholder="Do you want to share?"
              required
            />
          </div>
        </Dialog>

        <Dialog
          visible={deleteNotificationDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteNotificationDialogFooter}
          onHide={hideDeleteNotificationDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {notification && (
              <span>
                Are you sure you want to delete <b>{notification.message}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteNotificationsDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteNotificationsDialogFooter}
          onHide={hideDeleteNotificationsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {notification && (
              <span>
                Are you sure you want to delete the selected notifications?
              </span>
            )}
          </div>
        </Dialog>
      </div>
    </article>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { Box, ThemeProvider } from '@mui/material'
import '../css/App.css'
import { Breadcrumbs } from './Breadcrumbs'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { theme } from './AppUtil'
import Pdf from '../assets/pdf.svg'

export const Reports = () => {
  const [reports, setReports] = useState([])
  const dtReport = useRef(null)
  const [selectedReports, setSelectedReports] = useState(null)
  const [globalReportFilter, setGlobalReportFilter] =
    useState(null)
  const { t } = useTranslation()
  const getPDFs = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/documents`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setReports(response.data)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  useEffect(() => {
    getPDFs()
  }, [])

  const headerReport = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="flex flex-wrap gap-2">
        <span>{t('reports')}</span>
      </div>
      <TextField
        type="search"
        placeholder="Search..."
        value={globalReportFilter}
        onInput={(e) => setGlobalReportFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: '8px' }}/>
            </InputAdornment>
          )
        }}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )

  return (
    <ThemeProvider theme={theme}> <Breadcrumbs/>
      <Box sx={{
        padding: 2,
        backgroundColor: 'bbox.main',
        color: 'secondary.main',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
      }}>
      <Box>
        <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
              >
                <article>
                  {reports.length !== 0 && reports.some(item => item.description === 'pdf')
                    ? (reports.map((item, index) => (
                        item.description === 'pdf' && (
                        // eslint-disable-next-line react/jsx-key
                        <DataTable
                          key={index}
                          ref={dtReport}
                          value={reports}
                          selection={selectedReports}
                          onSelectionChange={(e) => setSelectedReports(e.value)}
                          dataKey="id"
                          paginator
                          stripedRows
                          size={'small'}
                          rows={10}
                          editMode="row"
                          sortMode="multiple"
                          rowsPerPageOptions={[5, 10, 25]}
                          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                          globalFilter={globalReportFilter}
                          header={headerReport}
                        >
                          <Column exportable={true}></Column>
                          <Column
                            field="year"
                            header={t('year')}
                            style={{ minWidth: '4rem' }}
                          ></Column>
                          <Column
                            field="title"
                            header={t('title')}
                            style={{ minWidth: '12rem' }}
                          ></Column>
                          <Column
                            field="description"
                            header={t('description')}
                            body={(rowData) => (
                                <img className="pdf-icon" id="leftcol" src={Pdf} alt="pdf file"/>
                            )}
                            style={{ minWidth: '8rem' }}
                          ></Column>
                          <Column
                            field="link"
                            header={t('link')}
                            body={(rowData) => (
                              <a href={rowData.link} target="_blank" rel="noopener noreferrer" download>
                                {t('download_doc')}
                              </a>
                            )}
                            style={{ minWidth: '13rem' }}
                          ></Column>
                        </DataTable>
                        ))))
                    : (
                      <div className="tip-wrapper">
                        <b>{t('no_document')}{' '}</b>
                      </div>
                      )}
                </article>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      </Box>
    </ThemeProvider>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { Box, ThemeProvider } from '@mui/material'
import '../css/App.css'
import { Breadcrumbs } from './Breadcrumbs'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { theme } from './AppUtil'

export const News = () => {
  const [news, setNews] = useState([])
  const dtReport = useRef(null)
  const [selectedNotifications, setSelectedNotifications] = useState(null)
  const [globalNotificationFilter, setGlobalNotificationsFilter] =
    useState(null)
  const { t } = useTranslation()
  const getNotifications = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/notifications`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setNews(response.data)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  useEffect(() => {
    getNotifications()
  }, [])

  const shareNews = news.filter(newz => newz.share === 'Yes')

  const headerNews = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="flex flex-wrap gap-2">
        <span>{t('news')}</span>
      </div>
      <TextField
        type="search"
        placeholder="Search..."
        value={globalNotificationFilter}
        onInput={(e) => setGlobalNotificationsFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: '8px' }}/>
            </InputAdornment>
          )
        }}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )

  return (
    <ThemeProvider theme={theme}> <Breadcrumbs/>
      <Box sx={{
        padding: 2,
        backgroundColor: 'bbox.main',
        color: 'secondary.main',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
      }}>
        <Box>
          <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                >
                  <article>
                    <DataTable
                      ref={dtReport}
                      value={shareNews}
                      selection={selectedNotifications}
                      onSelectionChange={(e) => setSelectedNotifications(e.value)}
                      dataKey="id"
                      paginator
                      stripedRows
                      size={'small'}
                      rows={10}
                      editMode="row"
                      sortMode="multiple"
                      rowsPerPageOptions={[5, 10, 25]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                      globalFilter={globalNotificationFilter}
                      header={headerNews}
                    >
                      <Column exportable={true}></Column>
                      <Column
                        field="added_on"
                        header={t('added_on')}
                        style={{ minWidth: '4rem' }}
                      ></Column>
                      <Column
                        field="author"
                        header={t('author')}
                        style={{ minWidth: '6rem' }}
                      ></Column>
                      <Column
                        field="message"
                        header={t('message')}
                        style={{ minWidth: '24rem' }}
                      ></Column>
                    </DataTable>
                  </article>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

import React, { useState, useEffect, useRef, Fragment } from 'react'
import axios from 'axios'
import '../css/App.css'
import FileSaver from 'file-saver'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { ButtonTaltech } from './ButtonTaltech'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import * as xlsx from 'xlsx'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'

export const TranslationEu = () => {
  const emptyTranslation = {
    translation_url: ''
  }
  const [translation, setTranslation] = useState('')
  const [translations, setTranslations] = useState([])
  const [translationResponse, setTranslationResponse] = useState('')
  const [translationData, setTranslationUpdateData] = useState('')
  const [translationDialog, setTranslationDialog] = useState(false)
  const [translationUpdateDialog, setTranslationUpdateDialog] = useState(false)
  const [deleteTranslationDialog, setDeleteTranslationDialog] = useState(false)
  const [deleteTranslationsDialog, setDeleteTranslationsDialog] = useState(false)
  const [selectedTranslations, setSelectedTranslations] = useState(null)
  const [submittedTranslation, setSubmittedTranslation] = useState(false)
  const [globalTranslationFilter, setGlobalTranslationFilter] = useState(null)
  const toastTranslation = useRef(null)
  const dtTranslation = useRef(null)

  const getTranslations = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/translation_docs`)
      .then((response) => {
        setTranslations(response.data)
        // eslint-disable-next-line no-console
        console.log(translations)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  useEffect(() => {
    getTranslations()
  }, [])

  function handleTranslationUpdate (id) {
    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/asset/translation_docs/${id}`, translation, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
      .then((response) => {
        setTranslationUpdateData(response.data)
        // eslint-disable-next-line no-console
        console.log(`Updated translations- ${translationData}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  function onDeleteTranslation (id) {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/asset/translation_docs/${id}`)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response.data)
        // eslint-disable-next-line no-console
        console.log(`Deleted translation with ID ${id}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  const onTranslationInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    const _translation = { ...translation }
    _translation[`${name}`] = val
    setTranslation(_translation)
  }

  const handleAddTranslation = (e) => {
    e.preventDefault()
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/asset/translation_docs`, translation, {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
      })
      .then(
        (response) => {
          setTranslationResponse(response)
          // eslint-disable-next-line no-console
          console.log(translationResponse)
          saveTranslation()
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error.message)
        }
      )
  }

  const openNewTranslation = () => {
    setTranslation(emptyTranslation)
    setSubmittedTranslation(false)
    setTranslationDialog(true)
  }

  const hideTranslationDialog = () => {
    setSubmittedTranslation(false)
    setTranslationDialog(false)
  }

  const hideTranslationUpdateDialog = () => {
    setSubmittedTranslation(false)
    setTranslationUpdateDialog(false)
  }

  const hideDeleteTranslationDialog = () => {
    setDeleteTranslationDialog(false)
  }

  const hideDeleteTranslationsDialog = () => {
    setDeleteTranslationsDialog(false)
  }
  const findTranslationIndexById = (id) => {
    let index = -1
    for (let i = 0; i < translations.length; i++) {
      if (translations[i].id === id) {
        index = i
        break
      }
    }
    return index
  }

  const saveTranslation = () => {
    setSubmittedTranslation(true)
    getTranslations()
    toastTranslation.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Translations Created',
      life: 3000
    })
    setTranslationDialog(false)
    setTranslation(emptyTranslation)
  }

  const updateTranslation = () => {
    const _translations = [...translations]
    const _translation = { ...translation }
    if (translation.id) {
      const index = findTranslationIndexById(translation.id)
      _translations[index] = _translation
      handleTranslationUpdate(translation.id)
      toastTranslation.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'Translation Updated',
        life: 3000
      })
    }
    setTranslations(_translations)
    setTranslationUpdateDialog(false)
    setTranslation(emptyTranslation)
  }

  const editTranslation = (translation) => {
    setTranslation({ ...translation })
    setTranslationUpdateDialog(true)
  }

  const confirmDeleteTranslation = (translation) => {
    setTranslation(translation)
    setDeleteTranslationDialog(true)
  }

  const deleteTranslation = () => {
    const _translations = translations.filter((val) => val.id !== translation.id)
    onDeleteTranslation(translation.id)
    setTranslations(_translations)
    setDeleteTranslationDialog(false)
    setTranslation(emptyTranslation)
    toastTranslation.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Translation Deleted',
      life: 3000
    })
  }

  const exportTranslationExcel = () => {
    const worksheet = xlsx.utils.json_to_sheet(translations)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    saveAsExcelFile(excelBuffer, 'translation_urls')
  }

  const saveAsExcelFile = (buffer, fileName) => {
    const data = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    })
    FileSaver.saveAs(data, fileName + '_translations_' + '.xlsx')
  }

  const deleteSelectedTranslations = () => {
    const _translations = translations.filter(
      (val) => !deleteSelectedTranslations.includes(val)
    )

    setTranslations(_translations)
    setDeleteTranslationsDialog(false)
    setSelectedTranslations(null)
    toastTranslation.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Translations Deleted',
      life: 3000
    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editTranslation(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteTranslation(rowData)}
        />
      </Fragment>
    )
  }

  const headerTranslation = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="flex flex-wrap gap-2">
        <ButtonTaltech size="small" label="Export" onClick={exportTranslationExcel} />
        {translations.length !== 0
          ? (<></>)
          : (
            <ButtonTaltech size="small" label="New" onClick={openNewTranslation} />
            )}
      </div>
      <TextField
        type="search"
        placeholder="Search..."
        value={globalTranslationFilter}
        onInput={(e) => setGlobalTranslationFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: '8px' }} />
            </InputAdornment>
          )
        }}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )

  const translationDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideTranslationDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={handleAddTranslation} />
    </Fragment>
  )

  const translationUpdateDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideTranslationUpdateDialog}
      />
      <Button label="Update" icon="pi pi-check" onClick={updateTranslation} />
    </Fragment>
  )

  const deleteTranslationDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteTranslationDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteTranslation}
      />
    </Fragment>
  )

  const deleteTranslationsDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteTranslationsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedTranslations}
      />
    </Fragment>
  )

  return (
    <article>
      <div>
        <Toast ref={toastTranslation} />
        <div className="card">
          <DataTable
            ref={dtTranslation}
            value={translations}
            selection={selectedTranslations}
            onSelectionChange={(e) => setSelectedTranslations(e.value)}
            dataKey="id"
            stripedRows
            size={'small'}
            rows={10}
            editMode="row"
            sortMode="multiple"
            globalFilter={globalTranslationFilter}
            header={headerTranslation}
          >
            <Column
              field="id"
              header="ID"
              style={{ minWidth: '2rem', display: 'none' }}
            ></Column>
            <Column
              field="translation_url"
              header="Translation url"
              style={{ minWidth: '8rem' }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: '9rem' }}
            ></Column>
          </DataTable>
        </div>
        <Dialog
          visible={translationUpdateDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Modify translation"
          modal
          className="p-fluid"
          footer={translationUpdateDialogFooter}
          onHide={hideTranslationUpdateDialog}
        >
          <div className="field">
            <label
              htmlFor="translation_url_update"
              className="font-bold labelAsterix p-required"
            >
              Translation url
            </label>
            <InputTextarea
              id="translation_url_update"
              value={translation.translation_url}
              onChange={(e) => onTranslationInputChange(e, 'translation_url')}
              rows={3}
              cols={20}
              required
              className={classNames({
                'p-invalid': submittedTranslation && !translation.translation_url
              })}
            />
          </div>
        </Dialog>

        <Dialog
          visible={translationDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Add Translations"
          modal
          className="p-fluid"
          footer={translationDialogFooter}
          onHide={hideTranslationDialog}
        >
          <Typography variant="subtitle3" component="div">
            Ensure that the url adding has general access granted. <a href="https://shorturl.at/zqDAw" target="_blank" rel="noreferrer">**hint**</a>
          </Typography>
          <br/>
          <div className="field">
            <label htmlFor="translation_url" className="font-bold labelAsterix p-required">
              Translation url
            </label>
            <InputTextarea
              id="translation_url"
              value={translation.translation_url}
              onChange={(e) => onTranslationInputChange(e, 'translation_url')}
              rows={3}
              cols={20}
              required
            />
          </div>
        </Dialog>

        <Dialog
          visible={deleteTranslationDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteTranslationDialogFooter}
          onHide={hideDeleteTranslationDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {translation && (
              <span>
                Are you sure you want to delete <b>{translation.title}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteTranslationsDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteTranslationsDialogFooter}
          onHide={hideDeleteTranslationsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {translation && (
              <span>Are you sure you want to delete the selected translations?</span>
            )}
          </div>
        </Dialog>
      </div>
    </article>
  )
}

import React, { useState, useEffect, useRef, Fragment } from 'react'
import axios from 'axios'
import '../css/App.css'
import FileSaver from 'file-saver'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import { ButtonTaltech } from './ButtonTaltech'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import * as xlsx from 'xlsx'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'

export const Document = () => {
  const emptyDoc = {
    year: 0,
    name: '',
    title: '',
    description: '',
    link: ''
  }
  const [docResponse, setDocResponse] = useState('')
  const [docData, setDocumentUpdateData] = useState('')
  const [docDialog, setDocDialog] = useState(false)
  const [docUpdateDialog, setDocUpdateDialog] = useState(false)
  const [deleteDocDialog, setDeleteDocDialog] = useState(false)
  const [deleteDocsDialog, setDeleteDocsDialog] = useState(false)
  const [document, setDocument] = useState('')
  const [documents, setDocuments] = useState(null)
  const [selectedDocs, setSelectedDocs] = useState(null)
  const [submittedDoc, setSubmittedDoc] = useState(false)
  const [globalDocFilter, setGlobalDocFilter] = useState(null)
  const toastDoc = useRef(null)
  const dtDoc = useRef(null)
  const years = Array.from({ length: 55 }, (_, i) => ({
    name: `${new Date().getFullYear() - 5 + i}`,
    value: new Date().getFullYear() - 5 + i
  }))
  const names = [
    { name: 'estonian_default', value: 'estonian_default' },
    { name: 'english_default', value: 'english_default' },
    { name: 'finnish_default', value: 'finish_default' },
    { name: 'swedish_default', value: 'swedish_default' }
  ]

  const descriptions = [
    { name: 'Pdf', value: 'pdf' },
    { name: 'Google_Sheets', value: 'Google Sheets' }
  ]

  const titles = [
    { name: 'Api_guide', value: 'Api guide' },
    { name: 'BOM Revit Template', value: 'BOM Revit template(ENG)' },
    { name: 'BOM Archicad template', value: 'BOM Archicad template(ENG)' },
    { name: 'Conversion_factors_for_organisations', value: 'Conversion factors for organisations' },
    { name: 'Estonian_construction_material_emissions_data(ENG)', value: 'Estonian construction material emissions data(ENG)' },
    { name: 'Estonian_construction_material_emissions_data(EST)', value: 'Estonian construction material emissions data(EST)' },
    {
      name: 'Estonian_construction_materials_emission_data-method report',
      value: 'Estonian_construction_materials_emission_data - method report'
    },
    {
      name: 'Estonian_emission_factors_for_organizations-method report',
      value: 'Estonian_emission_factors_for_organizations - method report'
    },
    { name: 'Estonian_EPDs', value: 'Estonian EPDs' }
  ]
  const getDocuments = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/documents`)
      .then((response) => {
        setDocuments(response.data)
        console.log(documents)
      })
      .catch((error) => {
        console.error('There was an error!', error.message)
      })
  }

  useEffect(() => {
    getDocuments()
  }, [])

  function handleDocUpdate (id) {
    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/asset/documents/${id}`, document, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
      .then((response) => {
        setDocumentUpdateData(response.data)
        // eslint-disable-next-line no-console
        console.log(`Updated document- ${docData}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  function onDeleteDocument (id) {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/asset/documents/${id}`)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response.data)
        // eslint-disable-next-line no-console
        console.log(`Deleted document with ID ${id}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  const onDocumentInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    const _document = { ...document }
    _document[`${name}`] = val
    setDocument(_document)
  }

  const handleAddDoc = (e) => {
    e.preventDefault()
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/asset/documents`, document, {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
      })
      .then(
        (response) => {
          setDocResponse(response)
          // eslint-disable-next-line no-console
          console.log(docResponse)
          saveDoc()
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error.message)
        }
      )
  }

  const openNewDoc = () => {
    setDocument(emptyDoc)
    setSubmittedDoc(false)
    setDocDialog(true)
  }

  const hideDocDialog = () => {
    setSubmittedDoc(false)
    setDocDialog(false)
  }

  const hideDocUpdateDialog = () => {
    setSubmittedDoc(false)
    setDocUpdateDialog(false)
  }

  const hideDeleteDocDialog = () => {
    setDeleteDocDialog(false)
  }

  const hideDeleteDocsDialog = () => {
    setDeleteDocsDialog(false)
  }
  const findDocumentIndexById = (id) => {
    let index = -1
    for (let i = 0; i < documents.length; i++) {
      if (documents[i].id === id) {
        index = i
        break
      }
    }
    return index
  }

  const saveDoc = () => {
    setSubmittedDoc(true)
    getDocuments()
    toastDoc.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Document Created',
      life: 3000
    })
    setDocDialog(false)
    setDocument(emptyDoc)
  }

  const updateDoc = () => {
    const _documents = [...documents]
    const _document = { ...document }
    if (document.id) {
      const index = findDocumentIndexById(document.id)
      _documents[index] = _document
      handleDocUpdate(document.id)
      toastDoc.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'Document Updated',
        life: 3000
      })
    }
    setDocuments(_documents)
    setDocUpdateDialog(false)
    setDocument(emptyDoc)
  }

  const editDoc = (document) => {
    setDocument({ ...document })
    setDocUpdateDialog(true)
  }

  const confirmDeleteDoc = (document) => {
    setDocument(document)
    setDeleteDocDialog(true)
  }

  const deleteDoc = () => {
    const _documents = documents.filter((val) => val.id !== document.id)
    onDeleteDocument(document.id)
    setDocuments(_documents)
    setDeleteDocDialog(false)
    setDocument(emptyDoc)
    toastDoc.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Document Deleted',
      life: 3000
    })
  }

  /*  const exportDocCSV = () => {
    dtDoc.current.exportCSV();
  }; */

  const exportDocExcel = () => {
    const worksheet = xlsx.utils.json_to_sheet(documents)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    saveAsExcelFile(excelBuffer, 'Emission_factor')
  }

  const saveAsExcelFile = (buffer, fileName) => {
    const data = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    })
    FileSaver.saveAs(data, fileName + '_documents_' + '.xlsx')
  }

  const deleteSelectedDocs = () => {
    const _documents = documents.filter(
      (val) => !deleteSelectedDocs.includes(val)
    )

    setDocuments(_documents)
    setDeleteDocsDialog(false)
    setSelectedDocs(null)
    toastDoc.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Documents Deleted',
      life: 3000
    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editDoc(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteDoc(rowData)}
        />
      </Fragment>
    )
  }

  const headerDoc = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="flex flex-wrap gap-2">
        <ButtonTaltech size="small" label="Export" onClick={exportDocExcel}/>
        <ButtonTaltech size="small" label="New" onClick={openNewDoc}/>
      </div>
      <TextField
        type="search"
        placeholder="Search..."
        value={globalDocFilter}
        onInput={(e) => setGlobalDocFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: '8px' }}/>
            </InputAdornment>
          )
        }}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )

  const docDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDocDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={handleAddDoc}/>
    </Fragment>
  )

  const docUpdateDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideDocUpdateDialog}
      />
      <Button label="Update" icon="pi pi-check" onClick={updateDoc}/>
    </Fragment>
  )

  const deleteDocDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteDocDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteDoc}
      />
    </Fragment>
  )

  const deleteDocsDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteDocsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedDocs}
      />
    </Fragment>
  )

  return (
    <article>
      <div>
        <Toast ref={toastDoc}/>
        <div className="card">
          <DataTable
            ref={dtDoc}
            value={documents}
            selection={selectedDocs}
            onSelectionChange={(e) => setSelectedDocs(e.value)}
            dataKey="id"
            paginator
            stripedRows
            size={'small'}
            rows={10}
            editMode="row"
            sortMode="multiple"
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} documents"
            globalFilter={globalDocFilter}
            header={headerDoc}
          >
            <Column
              field="id"
              header="ID"
              style={{ minWidth: '2rem', display: 'none' }}
            ></Column>
            <Column
              field="year"
              header="Year"
              style={{ minWidth: '4rem' }}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ minWidth: '8rem' }}
            ></Column>
            <Column
              field="title"
              header="Title"
              style={{ minWidth: '12rem' }}
            ></Column>
            <Column
              field="description"
              header="Description"
              style={{ minWidth: '8rem' }}
            ></Column>
            <Column
              field="link"
              header="Link"
              style={{ minWidth: '13rem' }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: '9rem' }}
            ></Column>
          </DataTable>
        </div>
        <Dialog
          visible={docUpdateDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Modify document"
          modal
          className="p-fluid"
          footer={docUpdateDialogFooter}
          onHide={hideDocUpdateDialog}
        >
          <div className="field">
            <label htmlFor="year_update" className="font-bold">
              Year
            </label>
            <Dropdown
              id="year_update"
              value={document.year}
              onChange={(e) => onDocumentInputChange(e, 'year')}
              options={years}
              optionLabel="name"
              placeholder="Select year"
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="name_update"
              className="font-bold labelAsterix p-required"
            >
              Name
            </label>
            <Dropdown
              id="name_update"
              value={document.name}
              onChange={(e) => onDocumentInputChange(e, 'name')}
              options={names}
              optionLabel="name"
              placeholder="Select Name"
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="title_update"
              className="font-bold labelAsterix p-required"
            >
              Title
            </label>
            <Dropdown
              id="title_update"
              value={document.title}
              onChange={(e) => onDocumentInputChange(e, 'title')}
              options={titles}
              optionLabel="name"
              placeholder="Select Title"
              required
              className={classNames({
                'p-invalid': submittedDoc && !document.title
              })}
            />
          </div>
          <div className="field">
            <label
              htmlFor="description_update"
              className="font-bold labelAsterix p-required"
            >
              Description
            </label>
            <Dropdown
              id="description_update"
              value={document.description}
              onChange={(e) => onDocumentInputChange(e, 'description')}
              options={descriptions}
              optionLabel="name"
              placeholder="Select Description"
              required
            />
          </div>
          <div className="field">
            <label
              htmlFor="link_update"
              className="font-bold labelAsterix p-required"
            >
              Link
            </label>
            <InputTextarea
              id="link_update"
              value={document.link}
              onChange={(e) => onDocumentInputChange(e, 'link')}
              rows={3}
              cols={20}
              required
            />
          </div>
        </Dialog>

        <Dialog
          visible={docDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Add Document"
          modal
          className="p-fluid"
          footer={docDialogFooter}
          onHide={hideDocDialog}
        >
          <Typography variant="subtitle3" component="div">
            Ensure that the url adding has general access granted. <a href="https://shorturl.at/zqDAw" target="_blank"
                                                                      rel="noreferrer">**hint**</a>
          </Typography>
          <br/>
          <div className="field">
            <label htmlFor="year" className="font-bold labelAsterix p-required">
              Year
            </label>
            <Dropdown
              value={document.year}
              onChange={(e) => onDocumentInputChange(e, 'year')}
              options={years}
              optionLabel="name"
              placeholder="Select year"
              required
            />
          </div>
          <div className="field">
            <label htmlFor="name" className="font-bold labelAsterix p-required">
              Name
            </label>
            <Dropdown
              value={document.name}
              onChange={(e) => onDocumentInputChange(e, 'name')}
              options={names}
              optionLabel="name"
              placeholder="Select Name"
            />
          </div>
          <div className="field">
            <label htmlFor="title" className="font-bold labelAsterix p-required">
              Title
            </label>
            <Dropdown
              value={document.title}
              onChange={(e) => onDocumentInputChange(e, 'title')}
              options={titles}
              optionLabel="name"
              placeholder="Select Title"
            />
          </div>
          <div className="field">
            <label
              htmlFor="description"
              className="font-bold labelAsterix p-required"
            >
              Description
            </label>
            <Dropdown
              value={document.description}
              onChange={(e) => onDocumentInputChange(e, 'description')}
              options={descriptions}
              optionLabel="name"
              placeholder="Select Description"
            />
          </div>
          <div className="field">
            <label htmlFor="link" className="font-bold labelAsterix p-required">
              Link
            </label>
            <InputTextarea
              id="link"
              value={document.link}
              onChange={(e) => onDocumentInputChange(e, 'link')}
              rows={3}
              cols={20}
              required
            />
          </div>
        </Dialog>

        <Dialog
          visible={deleteDocDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteDocDialogFooter}
          onHide={hideDeleteDocDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {document && (
              <span>
                Are you sure you want to delete <b>{document.title}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteDocsDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteDocsDialogFooter}
          onHide={hideDeleteDocsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {document && (
              <span>Are you sure you want to delete the selected users?</span>
            )}
          </div>
        </Dialog>
      </div>
    </article>
  )
}

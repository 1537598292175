import React, { useState, useEffect, useRef, Fragment } from 'react'
import axios from 'axios'
import '../css/App.css'
import FileSaver from 'file-saver'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { ButtonTaltech } from './ButtonTaltech'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import * as xlsx from 'xlsx'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'

export const MaterialGwp = () => {
  const emptyMaterialGwp = {
    material_url: ''
  }
  const [materialGwpDialog, setMaterialGwpDialog] = useState(false)
  const [materialGwpUpdateDialog, setMaterialGwpUpdateDialog] = useState(false)
  const [materialGwpData, setMaterialGwpUpdateData] = useState('')
  const [materialGwpResponse, setMaterialGwpResponse] = useState('')
  const [deleteMaterialGwpDialog, setDeleteMaterialGwpDialog] = useState(false)
  const [deleteMaterialGwpsDialog, setDeleteMaterialGwpsDialog] = useState(false)
  const [materialGwp, setMaterialGwp] = useState('')
  const [materialGwps, setMaterialGwps] = useState([])
  const [selectedMaterialGwps, setSelectedMaterialGwps] = useState(null)
  const [submittedMaterialGwp, setSubmittedMaterialGwp] = useState(false)
  const [globalMaterialGwpFilter, setGlobalMaterialGwpFilter] = useState(null)
  const toastMaterialGwp = useRef(null)
  const dtMaterialGwp = useRef(null)

  const getMaterialGwps = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/construction_docs`)
      .then((response) => {
        setMaterialGwps(response.data)
        // eslint-disable-next-line no-console
        console.log(materialGwps)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  useEffect(() => {
    getMaterialGwps()
  }, [])

  function handleMaterialGwpUpdate (id) {
    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/asset/construction_docs/${id}`, materialGwp, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
      .then((response) => {
        setMaterialGwpUpdateData(response.data)
        // eslint-disable-next-line no-console
        console.log(`Updated building materials gwp- ${materialGwpData}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  function onDeleteMaterialGwp (id) {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/asset/materialgwps/${id}`)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response.data)
        // eslint-disable-next-line no-console
        console.log(`Deleted building material GWPs with ID ${id}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  const onMaterialGwpInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    const _buildingsMaterialGwp = { ...materialGwp }
    _buildingsMaterialGwp[`${name}`] = val
    setMaterialGwp(_buildingsMaterialGwp)
  }

  const handleAddMaterialGwp = (e) => {
    e.preventDefault()
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/asset/construction_docs`, materialGwp, {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
      })
      .then(
        (response) => {
          setMaterialGwpResponse(response)
          // eslint-disable-next-line no-console
          console.log(materialGwpResponse)
          saveMaterialGwp()
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error.message)
        }
      )
  }

  const openNewMaterialGwp = () => {
    setMaterialGwp(emptyMaterialGwp)
    setSubmittedMaterialGwp(false)
    setMaterialGwpDialog(true)
  }

  const hideMaterialGwpDialog = () => {
    setSubmittedMaterialGwp(false)
    setMaterialGwpDialog(false)
  }

  const hideMaterialGwpUpdateDialog = () => {
    setSubmittedMaterialGwp(false)
    setMaterialGwpUpdateDialog(false)
  }

  const hideDeleteMaterialGwpDialog = () => {
    setDeleteMaterialGwpDialog(false)
  }

  const hideDeleteMaterialGwpsDialog = () => {
    setDeleteMaterialGwpsDialog(false)
  }
  const findMaterialGwpIndexById = (id) => {
    let index = -1
    for (let i = 0; i < materialGwps.length; i++) {
      if (materialGwps[i].id === id) {
        index = i
        break
      }
    }
    return index
  }

  const saveMaterialGwp = () => {
    setSubmittedMaterialGwp(true)
    getMaterialGwps()
    toastMaterialGwp.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Building Material GWP Created',
      life: 3000
    })
    setMaterialGwpDialog(false)
    setMaterialGwp(emptyMaterialGwp)
  }

  const updateMaterialGwp = () => {
    const _materialGwp = { ...materialGwp }
    const _materialGwps = { ...materialGwps }
    if (materialGwp.id) {
      const index = findMaterialGwpIndexById(materialGwp.id)
      _materialGwps[index] = _materialGwp
      handleMaterialGwpUpdate(materialGwp.id)
      toastMaterialGwp.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'Building materialGWP Updated',
        life: 3000
      })
    }
    setMaterialGwps(_materialGwps)
    setMaterialGwpUpdateDialog(false)
    setMaterialGwp(emptyMaterialGwp)
  }

  const editMaterialGwp = (materialGwp) => {
    setMaterialGwp({ ...materialGwp })
    setMaterialGwpUpdateDialog(true)
  }

  const confirmDeleteMaterialGwp = (materialGwp) => {
    setMaterialGwp(materialGwp)
    setDeleteMaterialGwpDialog(true)
  }

  const deleteMaterialGwp = () => {
    const _materialGwps = materialGwps.filter((val) => val.id !== materialGwp.id)
    onDeleteMaterialGwp(materialGwp.id)
    setMaterialGwps(_materialGwps)
    setDeleteMaterialGwpDialog(false)
    setMaterialGwp(emptyMaterialGwp)
    toastMaterialGwp.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Building materialGWP Deleted',
      life: 3000
    })
  }

  /*  const exportDocCSV = () => {
    dtDoc.current.exportCSV();
  }; */

  const exportMaterialGwpExcel = () => {
    const worksheet = xlsx.utils.json_to_sheet(materialGwps)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    saveAsExcelFile(excelBuffer, 'Building_materials_gwp')
  }

  const saveAsExcelFile = (buffer, fileName) => {
    const data = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    })
    FileSaver.saveAs(data, fileName + '_buildingMaterialGwp_' + '.xlsx')
  }

  const deleteSelectedMaterialGwp = () => {
    const _materialGwps = materialGwps.filter(
      (val) => !deleteSelectedMaterialGwp().includes(val)
    )

    setMaterialGwp(_materialGwps)
    setDeleteMaterialGwpsDialog(false)
    setSelectedMaterialGwps(null)
    toastMaterialGwp.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Building materialGWP Deleted',
      life: 3000
    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editMaterialGwp(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteMaterialGwp(rowData)}
        />
      </Fragment>
    )
  }

  const headerBuildingsMaterialGwp = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="flex flex-wrap gap-2">
        <ButtonTaltech size="small" label="Export" onClick={exportMaterialGwpExcel} />
        {materialGwps.length !== 0
          ? (<></>)
          : (
          <ButtonTaltech size="small" label="New" onClick={openNewMaterialGwp} />
            )}
      </div>
      <TextField
        type="search"
        placeholder="Search..."
        value={globalMaterialGwpFilter}
        onInput={(e) => setGlobalMaterialGwpFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: '8px' }} />
            </InputAdornment>
          )
        }}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )

  const materialGwpDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideMaterialGwpDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={handleAddMaterialGwp} />
    </Fragment>
  )

  const materialGwpUpdateDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideMaterialGwpUpdateDialog}
      />
      <Button label="Update" icon="pi pi-check" onClick={updateMaterialGwp} />
    </Fragment>
  )

  const deleteBuildingsMaterialGwpDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteMaterialGwpDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteMaterialGwp}
      />
    </Fragment>
  )

  const deleteBuildingsMaterialGwpsDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteMaterialGwpsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedMaterialGwp}
      />
    </Fragment>
  )

  return (
    <article>
      <div>
        <Toast ref={toastMaterialGwp} />
        <div className="card">
          <DataTable
            ref={dtMaterialGwp}
            value={materialGwps}
            selection={selectedMaterialGwps}
            onSelectionChange={(e) => setSelectedMaterialGwps(e.value)}
            dataKey="id"
            stripedRows
            size={'small'}
            rows={10}
            editMode="row"
            sortMode="multiple"
            globalFilter={globalMaterialGwpFilter}
            header={headerBuildingsMaterialGwp}
          >
            <Column
              field="material_url"
              header="Building materialGwp url"
              style={{ minWidth: '13rem' }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: '9rem' }}
            ></Column>
          </DataTable>
        </div>
        <Dialog
          visible={materialGwpUpdateDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Modify material GWPs"
          modal
          className="p-fluid"
          footer={materialGwpUpdateDialogFooter}
          onHide={hideMaterialGwpUpdateDialog}
        >
          <div className="field">
            <label
              htmlFor="materialurl_update"
              className="font-bold labelAsterix p-required"
            >
              Building materialGWP url
            </label>
            <InputTextarea
              id="materialurl_update"
              value={materialGwp.material_url}
              onChange={(e) => onMaterialGwpInputChange(e, 'material_url')}
              rows={3}
              cols={20}
              required
              className={classNames({
                'p-invalid': submittedMaterialGwp && !materialGwp.material_url
              })}
            />
          </div>
        </Dialog>

        <Dialog
          visible={materialGwpDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Add Building Material GWP"
          modal
          className="p-fluid"
          footer={materialGwpDialogFooter }
          onHide={hideMaterialGwpDialog}
        >
          <Typography variant="subtitle3" component="div">
            Ensure that the url adding has general access granted. <a href="https://shorturl.at/zqDAw" target="_blank" rel="noreferrer">**hint**</a>
          </Typography>
          <br/>
          <div className="field">
            <label htmlFor="material_url" className="font-bold labelAsterix p-required">
              Building MaterialGWP URL
            </label>
            <InputTextarea
              id="material_url"
              value={materialGwp.material_url}
              onChange={(e) => onMaterialGwpInputChange(e, 'material_url')}
              rows={3}
              cols={20}
              required
            />
          </div>
        </Dialog>

        <Dialog
          visible={deleteMaterialGwpDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteBuildingsMaterialGwpDialogFooter}
          onHide={hideDeleteMaterialGwpDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {materialGwps && (
              <span>
                Are you sure you want to delete <b>{materialGwps.material_url}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteMaterialGwpsDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteBuildingsMaterialGwpsDialogFooter}
          onHide={hideDeleteMaterialGwpsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {materialGwps && (
              <span>Are you sure you want to delete the selected material gwp url?</span>
            )}
          </div>
        </Dialog>
      </div>
    </article>
  )
}

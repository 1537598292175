import React, { Fragment, useEffect, useRef, useState } from 'react'

import axios from 'axios'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ButtonTaltech } from './ButtonTaltech'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

export const User = () => {
  const emptyUser = {
    id: 0,
    email: '',
    role: '',
    permission: ''
  }
  const [userUpdateData, setUserUpdateData] = useState('')
  const [userResponse, setUserResponse] = useState('')
  const [userDialog, setUserDialog] = useState(false)
  const [userUpdateDialog, setUserUpdateDialog] = useState(false)
  const [userData, setUserData] = useState({})
  const [user, setUser] = useState(emptyUser)
  const [users, setUsers] = useState(null)
  const [deleteUserDialog, setDeleteUserDialog] = useState(false)
  const [deleteUsersDialog, setDeleteUsersDialog] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState(null)
  /* const [submittedUser, setSubmittedUser] = useState(false); */
  const [updatedUser, setUpdatedUser] = useState(false)
  const [globalUserFilter, setGlobalUserFilter] = useState(null)
  const toastUser = useRef(null)
  const dtUser = useRef(null)

  const roles = [
    { name: 'Administrator', value: 'Administrator' },
    { name: 'General', value: 'General' }
  ]

  const permissions = [
    { name: 'All', value: 'All' },
    { name: 'View', value: 'View' },
    { name: 'Edit', value: 'Edit' }
  ]

  const getUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/users`)
      .then((response) => {
        setUsers(response.data)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  const handleAddUser = (e) => {
    e.preventDefault()
    if (user.email && user.role && user.permission) {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/asset/users`, user, {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json'
        })
        .then(
          (response) => {
            setUserResponse(response)
            // eslint-disable-next-line no-console
            console.log(userResponse)
            saveUser()
          },
          (error) => {
            // eslint-disable-next-line no-console
            console.log(error.message)
          }
        )
    } else {
      // eslint-disable-next-line no-console
      console.log('missing input fields')
    }
  }

  function handleUserUpdate (id) {
    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/asset/users/${id}`, user, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
      .then((response) => {
        setUserUpdateData(response.data)
        // eslint-disable-next-line no-console
        console.log(`Updated user ${userUpdateData}`)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  function onDeleteUser (id) {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/asset/users/${id}`)
      .then((response) => {
        setUserData(response.data)
        // eslint-disable-next-line no-console
        console.log(`Deleted user with ID ${id}`)
        // eslint-disable-next-line no-console
        console.log(userData)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  const onUserInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    const _user = { ...user }
    _user[`${name}`] = val
    setUser(_user)
  }

  useEffect(() => {
    getUsers()
  }, [])

  const openNewUser = () => {
    setUser(emptyUser)
    /* setSubmittedUser(false); */
    setUserDialog(true)
  }

  const hideUserDialog = () => {
    // setSubmittedUser(false);
    setUserDialog(false)
  }

  const hideUserUpdateDialog = () => {
    setUpdatedUser(false)
    setUserUpdateDialog(false)
  }

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false)
  }

  const hideDeleteUsersDialog = () => {
    setDeleteUsersDialog(false)
  }

  const findUserIndexById = (id) => {
    let index = -1
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === id) {
        index = i
        break
      }
    }
    return index
  }

  const saveUser = () => {
    /*   setSubmittedUser(true); */
    getUsers()
    toastUser.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'User Created',
      life: 3000
    })
    setUserDialog(false)
    setUser(emptyUser)
  }

  const updateUser = () => {
    const _users = [...users]
    const _user = { ...user }
    if (user.id) {
      const index = findUserIndexById(user.id)
      _users[index] = _user
      handleUserUpdate(user.id)
      toastUser.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'User Updated',
        life: 3000
      })
    }
    setUsers(_users)
    setUserUpdateDialog(false)
    setUser(emptyUser)
  }

  const confirmDeleteUser = (user) => {
    setUser(user)
    setDeleteUserDialog(true)
  }

  const editUser = (user) => {
    setUser({ ...user })
    setUserUpdateDialog(true)
  }

  const deleteUser = () => {
    const _users = users.filter((val) => val.id !== user.id)
    onDeleteUser(user.id)
    setUsers(_users)
    setDeleteUserDialog(false)
    setUser(emptyUser)
    toastUser.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'User Deleted',
      life: 3000
    })
  }

  const deleteSelectedUsers = () => {
    const _users = users.filter((val) => !deleteSelectedUsers.includes(val))

    setUsers(_users)
    setDeleteUsersDialog(false)
    setSelectedUsers(null)
    toastUser.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Users Deleted',
      life: 3000
    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editUser(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteUser(rowData)}
        />
      </Fragment>
    )
  }

  const headerUser = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="flex flex-wrap gap-2">
        <ButtonTaltech size="small" label="New" onClick={openNewUser} />
      </div>
      <TextField
        type="search"
        placeholder="Search..."
        value={globalUserFilter}
        onInput={(e) => setGlobalUserFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: '8px' }} />
            </InputAdornment>
          )
        }}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )

  const userDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideUserDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={handleAddUser} />
    </Fragment>
  )

  const userUpdateDialogFooter = (
    <Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideUserUpdateDialog}
      />
      <Button label="Update" icon="pi pi-check" onClick={updateUser} />
    </Fragment>
  )

  const deleteUserDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteUserDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteUser}
      />
    </Fragment>
  )

  const deleteUsersDialogFooter = (
    <Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteUsersDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedUsers}
      />
    </Fragment>
  )

  return (
    <article>
      <div>
        <Toast ref={toastUser} />
        <div className="card">
          <DataTable
            ref={dtUser}
            value={users}
            selection={selectedUsers}
            onSelectionChange={(e) => setSelectedUsers(e.value)}
            dataKey="id"
            paginator
            stripedRows
            size={'small'}
            rows={10}
            editMode="row"
            sortMode="multiple"
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalUserFilter}
            header={headerUser}
          >
            {/*  <Column selectionMode="multiple" exportable={false}></Column> */}
            <Column
              field="id"
              header="ID"
              style={{ minWidth: '2rem', display: 'none' }}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ minWidth: '10rem' }}
            ></Column>
            <Column
              field="role"
              header="Role"
              style={{ minWidth: '8rem' }}
            ></Column>
            <Column
              field="permission"
              header="Permission"
              style={{ minWidth: '8rem' }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: '8rem' }}
            ></Column>
          </DataTable>
        </div>

        <Dialog
          visible={userUpdateDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Modify user"
          modal
          className="p-fluid"
          footer={userUpdateDialogFooter}
          onHide={hideUserUpdateDialog}
        >
          <div className="field">
            <label
              htmlFor="email_update"
              className="font-bold labelAsterix p-required"
            >
              Email
            </label>
            <InputText
              id="email_update"
              name="email_update"
              placeholder="mujaro@taltech.ee"
              value={user.email}
              required
              autoFocus
              onChange={(e) => onUserInputChange(e, 'email')}
              className={classNames({
                'p-invalid': updatedUser && !user.email
              })}
            />
            {updatedUser && user.email.includes('@taltech.ee') && (
              <small className="p-error">Taltech email is required.</small>
            )}
          </div>
          <div className="field">
            <label
              htmlFor="role_update"
              className="font-bold labelAsterix p-required"
            >
              Role
            </label>
            <Dropdown
              value={user.role}
              name="role_update"
              id="role_update"
              options={roles}
              optionLabel="name"
              placeholder="Select role"
              required
              onChange={(e) => onUserInputChange(e, 'role')}
            />
          </div>
          <div className="field">
            <label
              htmlFor="permission_update"
              className="font-bold labelAsterix p-required"
            >
              Permission
            </label>
            <Dropdown
              name="permission_update"
              id="permission_update"
              value={user.permission}
              options={permissions}
              optionLabel="name"
              placeholder="Select permission"
              required
              onChange={(e) => onUserInputChange(e, 'permission')}
            />
          </div>
        </Dialog>

        <Dialog
          visible={userDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="New user"
          modal
          className="p-fluid"
          footer={userDialogFooter}
          onHide={hideUserDialog}
        >
          <div className="field">
            <label
              htmlFor="email"
              className="font-bold labelAsterix p-required"
            >
              Email
            </label>
            <InputText
              id="email"
              placeholder="mujaro@taltech.ee"
              value={user.email}
              onChange={(e) => onUserInputChange(e, 'email')}
              required
              autoFocus
              /* className={classNames({
                "p-invalid": !user.email,
              })} */
            />
            <span style={{ color: !user.email ? 'red' : 'black' }}>
              {user.email &&
              user.email.includes('@taltech.ee') &&
              user.email.length > 15
                ? (
                    ''
                  )
                : (
                <small className="p-error">Taltech email is required.</small>
                  )}
            </span>
          </div>
          <div className="field">
            <label htmlFor="role" className="font-bold labelAsterix p-required">
              Role
            </label>
            <Dropdown
              value={user.role}
              onChange={(e) => onUserInputChange(e, 'role')}
              options={roles}
              optionLabel="name"
              placeholder="Select role"
              required
            />
            <span style={{ color: !user.role ? 'red' : 'black' }}>
              {user.role
                ? (
                    ''
                  )
                : (
                <small className="p-error">Role is required.</small>
                  )}
            </span>
          </div>
          <div className="field">
            <label
              htmlFor="permission"
              className="font-bold labelAsterix p-required"
            >
              Permission
            </label>
            <Dropdown
              value={user.permission}
              onChange={(e) => onUserInputChange(e, 'permission')}
              options={permissions}
              optionLabel="name"
              placeholder="Select permission"
              required
            />
          </div>
        </Dialog>

        <Dialog
          visible={deleteUserDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteUserDialogFooter}
          onHide={hideDeleteUserDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {user && (
              <span>
                Are you sure you want to delete <b>{user.email}</b> ?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteUsersDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Confirm"
          modal
          footer={deleteUsersDialogFooter}
          onHide={hideDeleteUsersDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {user && (
              <span>Are you sure you want to delete the selected users?</span>
            )}
          </div>
        </Dialog>
      </div>
    </article>
  )
}

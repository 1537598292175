import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { Home } from '../component/Home'
import { Overview } from '../component/Overview'
import { Document } from '../component/Document'
import { NavDrawer } from '../component/NavDrawer'
import { User } from '../component/User'
import Notifications from '@mui/icons-material/Notifications'
import { MaterialGwp } from '../component/MaterialGwp'
import { DefaultSettings } from '../component/DefaultSettings'
import { TranslationEu } from '../component/TranslationEu'
import { Reports } from '../component/Reports'
import { News } from '../component/News'

export const AppRoutes = () => {
  return useRoutes([
    { path: '/', element: <Home/> },
    { path: 'reports', element: <Reports/> },
    { path: 'news', element: <News/> },
    { path: 'navdrawer', element: <NavDrawer/> },
    { path: 'dashboard', element: <Overview/> },
    { path: 'document', element: <Document/> },
    { path: 'user', element: <User/> },
    { path: 'notifications', element: <Notifications/> },
    { path: 'materialgwp', element: <MaterialGwp/> },
    { path: 'defaultSettings', element: <DefaultSettings/> },
    { path: 'translationEu', element: <TranslationEu/> },
    { path: '*', element: <Navigate to="/"/> }
  ])
}

import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#E4067E'
    },
    secondary: {
      main: '#342B60',
      txt: '#fff'
    },
    accent: {
      main: '#E4067E'
    },
    bbox: {
      main: '#DADAE4',
      secondary: '#9396b0'
    }
  }
})
